import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function NavScroll() {
  const element = document.getElementById('navbar')
  const navContent = document.getElementById('nav-content')
  const body = document.querySelector('body')

  element.style.paddingBottom = '2rem'
  element.style.transition = 'all 100ms ease-in-out'
  element.style.zIndex = '30'
  navContent.style.transition = 'all 100ms ease-in-out'
  element.style.position = 'fixed'

  let lastScroll = 0

  window.addEventListener('scroll', () => {
    const currentScroll = window.scrollY

    if (currentScroll <= 50) {
      element.style.backdropFilter = 'unset'
      element.style.background = 'unset'

      return
    }

    if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
      element.style.transform = 'translate3d(0,-110%,0)'
      navContent.style.transform = 'scale(0.8)'
    } else if (currentScroll < lastScroll) {
      element.style.transform = 'unset'
      navContent.style.transform = 'unset'

      // Safari requires webkit
      element.style.webkitBackdropFilter = 'blur(30px)'
      element.style.backdropFilter = 'blur(30px)'

      element.style.webkitMaskImage = 'linear-gradient(to bottom,black 90%,transparent 100%)'
      element.style.maskImage = 'linear-gradient(to bottom,black 90%,transparent 100%)'
    }
    lastScroll = currentScroll
  })
}
