import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function parallax() {
  document.querySelectorAll('[ck-parallax').forEach((element) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        // markers: true,
      },
    })

    tl.fromTo(element, { y: 200 }, { y: 0 })
  })
}
