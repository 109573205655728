import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function daysSince() {
  document.querySelectorAll('[ck-sticky]').forEach((element) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-section',
        start: `bottom bottom`,
        onEnter: () => {
          gsap.set(element, { position: 'fixed' })
        },
        onLeaveBack: () => {
          gsap.set(element, { position: 'absolute' })
        },
        // markers: true,
      },
    })
  })
}
