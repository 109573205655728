import { parse, formatDistanceToNow, format, differenceInYears, formatDistanceToNowStrict } from 'date-fns'

export default function daysSince() {
  document.querySelectorAll('[ck-dayssince-spill').forEach((element) => {
    const dateString = element.getAttribute('ck-dayssince')
    if (dateString) element.innerHTML = parseString(dateString)
  })

  document.querySelectorAll('[ck-dayssince-ticker').forEach((element) => {
    const dateString = element.getAttribute('ck-dayssince')
    if (dateString) element.innerHTML = parseString(dateString, 'Since Last Incident')
  })
}

function parseString(dateString, suffix = 'ago') {
  const date = parse(dateString, 'MMMM dd, yyyy', new Date())
  const yearsDifference = differenceInYears(new Date(), date)

  if (yearsDifference >= 1) {
    return format(date, 'MMM d, yyyy')
  } else {
    const difference = formatDistanceToNow(date).replace(/about|over|almost/g, '')
    return `${difference} ${suffix}`
  }
}
