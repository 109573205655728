import Accordions from './components/accordion'
import Anchors from './components/anchors'
import CountUp from './components/countUp'
import TextTransitions from './components/textTransitions'
import DaysSince from './components/daysSince'
import Parallax from './components/parallax'
import NavScroll from './components/navScroll'
import StickyTicker from './components/stickyTicker'

console.info('%c🔥CK Started!', 'color: red; font-size: 1rem;')

// accessibility enhancements
Accordions()
Anchors()

// Date Formatter
DaysSince()

// gsap
CountUp()
TextTransitions()
Parallax()
NavScroll()
StickyTicker()
