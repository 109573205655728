import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function textTransitions() {
  document.querySelectorAll('[ck-texttransition').forEach((element) => {
    const transition = element.getAttribute('ck-texttransition')
    const delay = element.getAttribute('ck-texttransition-delay') || 0

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        // markers: true,
      },
    })

    tl.fromTo(element, { opacity: 0 }, { opacity: 1, duration: 2, delay: delay })

    if (transition === 'fadeUp') {
      tl.fromTo(element, { transform: 'translateY(50%)' }, { transform: 'translateY(0%)', duration: 1 }, 0)
    }
  })
}
